import { modalContentMap } from "../models/modal-model";

export function initializeModal() {
    const modal: HTMLElement = document.getElementById("modal") as HTMLElement;
    const openModalbtn: NodeListOf<HTMLElement> = document.querySelectorAll(".trigger-modal");
    const modalContent = modal.querySelector(".modal-content")!;

    //Para abrir la modal
    openModalbtn.forEach(button => {
        button.addEventListener("click", (event: MouseEvent) => {
            event.preventDefault;
            const contentKey = button.getAttribute("data-content");
            if(contentKey && modalContentMap[contentKey]){
                modalContent.innerHTML = `
                     <i class="fa-regular fa-circle-xmark close-modal"></i>
                     ${modalContentMap[contentKey]}
                 `;
            }
            modal.classList.remove("hidden");

            //agregamos evento para cerrar modal
            modal.querySelector(".close-modal")?.addEventListener("click", () => {
                modal.classList.add("hidden");
              });
        })
    })
  

    // Cerrar modal haciendo click fuera
    window.addEventListener("click", (event: MouseEvent) => {
        if (event.target === modal) {
            modal.classList.add("hidden");
        }
    });

}

export function showPopups(type: string, response: string) {
    const popup = document.getElementById("popup") as HTMLElement;
    const popUpcontent = popup.querySelector(".popup-content") as HTMLElement;
    let contentKey = type;
    if (contentKey && modalContentMap[contentKey]) {
        popUpcontent.innerHTML = `
            ${modalContentMap[contentKey]}
            <p>${response}</p>
            <button class="close-popup btn-primary">Cerrar</button>
        `;
    }
    popup.querySelector(".close-popup")?.addEventListener("click", () => {
        closePopup();
    });
    popup.classList.remove("hiden");
}

export function closePopup() {
    const popup = document.getElementById("popup") as HTMLElement;
    popup.classList.add("hiden");
}