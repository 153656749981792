import { initializeHeader, loadYearCopy, responsiveMenu } from "./ts/landingdom";
import { initializeModal} from "./ts/modal";
import { controlContact, controlSubscription } from "./ts/contact";
import "./css/app.css";

document.addEventListener('DOMContentLoaded', () => {
    initializeHeader();
    controlContact();
    controlSubscription();
    loadYearCopy();
    // Inicializa otros módulos si es necesario
    initializeModal();
    responsiveMenu();
});
