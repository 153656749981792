export function showLoader() {
    const loader = document.getElementById('square-load');
    if (loader) {
        loader.classList.remove('hiden');
    }
}

export function hideLoader() {
    const loader = document.getElementById('square-load');
    if (loader) {
        loader.classList.add('hiden');
    }
}