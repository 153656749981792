import config from "../config";
import { showLoader, hideLoader } from "./loaders";
import { showPopups, closePopup } from "./modal";

export function controlContact(){
    const contactForm  = document.getElementById('contact-form') as HTMLFormElement;
    if(contactForm){
        contactForm.addEventListener('submit', async(event) => {
            event.preventDefault();
            showLoader();
            const formData = new FormData(contactForm);
            const data = {
                name: formData.get('name') as string,
                email: formData.get('email') as string,
                message: formData.get('message') as string
            };
            try {
                const response = await fetch(`${config.serverUrl}/contact`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });
                hideLoader();
                if(response.ok){
                    const result = await response.json();
                    showPopups('successend', result.message);
                    contactForm.reset();
                }
            } catch (error) {
                hideLoader();
                showPopups('dangersend', 'Error el correo no se ha podido enviar');
            }
        });
    }
}

export function controlSubscription(){
    const subscriptionForm = document.getElementById('subscription-form') as HTMLFormElement;
    if(subscriptionForm){
        subscriptionForm.addEventListener('submit', async(event) => {
            event.preventDefault();
            showLoader();
            const formData = new FormData(subscriptionForm);
            const data = {
                email: formData.get('correo') as string
            };
            try {
                const response = await fetch(`${config.serverUrl}/subscribe`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });
                hideLoader();
                if(response.ok){
                    const result = await response.json();
                    showPopups('successend', result.message);
                    subscriptionForm.reset();
                }
            } catch (error) {
                hideLoader();
                showPopups('dangersend', 'Error al generar la suscripción, intenta de nuevo');
            }
        });
    }
}