export const modalContentMap: Record<string, string> = {
    websync: `
      <h2>WebSync</h2>
      <p>Con WebSync, creamos aplicaciones web adaptables a la evolución de tu negocio. Integramos tecnología avanzada, diseño intuitivo y escalabilidad, garantizando que cada proyecto no solo funcione, sino que impulse tu crecimiento en el mundo digital.</p>
    `,
    comingsoon: `<h2>Proximamente</h2>`,
    team: `
    <div class="team-container">
      <!-- Primer miembro del equipo -->
      <div class="team-column">
        <img src="./assets/images/milan.jpg" alt="Miembro del equipo 1" class="team-member-image">
        <h3>Josue Israel Milán Escobar</h3>
        <p>
          Egresado de la carre de ITI en la Universidad Politecncia de San Luis Potosí
          Seniority TI en el campo de sistemas de información encargado del areá de
          Infraestructura y sistemas computacionales en la empresa M3rsync.  
        </p>
        <p class="team-member-email">israel.milan@m3rsync.com</p>
        <div class="team-member-socials">
          <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
          <a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a>
        </div>
      </div>

      <!-- Segundo miembro del equipo -->
      <div class="team-column">
        <img src="./assets/images/yo.jpg" alt="Miembro del equipo 2" class="team-member-image">
        <h3>Luis Enrique Munguia Morales</h3>
        <p>
          Egresodo de la carrera de ITI en la Universidad Politecnica de San Luis Potosí
          Desarrollador de software en la empresa M3rsync, encargado del area de desarrollo
          de aplicaciones web y moviles.
        </p>
        <p class="team-member-email">enrique.munguia@m3rsync.com</p>
        <div class="team-member-socials">
          <a href="https://www.linkedin.com/in/luis-enrique-munguía-morales-840a281a7/" target="_blank"><i class="fab fa-linkedin-in"></i></a>
          <a href="https://github.com/ErcokQ" target="_blank"><i class="fab fa-github"></i></a>
          <a href="./assets/docs/luis_enrique_munguia_cv.pdf" target="_blank"><i class="fa fa-briefcase"></i></a>
        </div>
      </div>
    </div>
  `,
    successend: `
                <i class="fa-regular fa-circle-check success-icon"></i>
                <h3>Datos enviados correctamente</h3>
    `,
    dangersend: `
                <i class="fa-regular fa-circle-xmark danger-icon"></i>
                <h3>Error al enviar los datos</h3>
    `,
};