export function initializeHeader() {
    const header = document.querySelector('header');

    if (!header) return;
  
    window.addEventListener('scroll', () => {
      if (window.scrollY > 50) { // Puedes ajustar el valor según tus necesidades
        header.classList.add('header-scrolled');
      } else {
        header.classList.remove('header-scrolled');
      }
    });
  }

export function loadYearCopy() {
    const currentYear: number = new Date().getFullYear();
  
    const currentYearElement = document.getElementById("currentYear");

    if (currentYearElement) {
      currentYearElement.textContent = currentYear.toString(); // Convertimos el número a string
    }
  }

export function responsiveMenu(){
  const checkmenu = document.getElementById('checkmenu') as HTMLInputElement;
  const menu = document.getElementById('menu');
  if(checkmenu && menu){
    checkmenu.addEventListener('change', () => {
       if(checkmenu.checked){
          menu.classList.add('show');
          menu.classList.remove('hide');
       }else{
          menu.classList.add('hide');
       }
    });
  }
}